import Snippets from './Snippets'
import { trustedTypes } from 'trusted-types'

const escapeHTMLPolicy = trustedTypes.createPolicy("CPC-CSP", {
    createHTML: string => string.replace(/\</g, '&lt;'),
    createScript: string => string.replace(/\</g, '&lt;'),
    createScriptURL: string => string.replace(/\</g, '&lt;')
});

window.trustedTypes.createPolicy('default', {createHTML: string => string})

const TagManager = {
  dataScript: function (dataLayer, nonce) {
    const script = document.createElement('script')

    if (nonce) {
      script.setAttribute('nonce', nonce);
    }

    script.innerHTML = escapeHTMLPolicy.createScript(dataLayer)
    return script
  },
  gtm: function (args) {
    const snippets = Snippets.tags(args)

    const noScript = () => {
      const noscript = document.createElement('noscript')
      // noscript.innerHTML = snippets.iframe
      return noscript
    }

    const script = (nonce, elementId) => {
      const script = document.createElement('script')

      if (elementId) {
        script.setAttribute('id', elementId);
      }

      if (nonce) {
        script.setAttribute('nonce', nonce);
      }

      const snippetScript = escapeHTMLPolicy.createHTML(snippets.script)

      script.innerHTML = snippetScript
      console.log(snippetScript)
      return script
    }

    const dataScript = (nonce) => this.dataScript(snippets.dataLayerVar, nonce)

    return {
      noScript,
      script,
      dataScript
    }
  },
  initialize: function ({ gtmId, events = {}, dataLayer, dataLayerName = 'dataLayer', auth = '', preview = '', nonce = '', elementId = '' }) {
    const gtm = this.gtm({
      id: gtmId,
      events: events,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName,
      auth,
      preview,
      nonce,
      elementId
    })
    if (dataLayer) document.head.appendChild(gtm.dataScript(nonce))
    document.head.insertBefore(gtm.script(nonce, elementId), document.head.childNodes[0])
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
  },
  dataLayer: function ({dataLayer, dataLayerName = 'dataLayer'}) {
    if (window[dataLayerName]) return window[dataLayerName].push(dataLayer)
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName)
    const dataScript = this.dataScript(snippets)
    document.head.insertBefore(dataScript, document.head.childNodes[0])
  }
}

export default TagManager;
